import * as React from "react";
import {Route, Switch} from "react-router";
import {ImprintPage} from "../../pages/imprintPage";
import {LocaleServiceV1} from "@volkswagen-onehub/locale-service";

export interface IRoutesProps {
    readonly localeService: LocaleServiceV1;
    readonly consentUrl: string;
}

export const Routes: React.FunctionComponent<IRoutesProps> = (props) => {
    return (
        <Switch>
            <Route path='/' render={() => <ImprintPage localeService={props.localeService} consentUrl={props.consentUrl} />}/>
        </Switch>
    );
};
