import {
    Container,
    ContainerWrap,
    ThemeProvider
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import styled from "styled-components";
import {LocaleServiceV1} from "@volkswagen-onehub/locale-service";
import {ServiceConfigProviderV1} from "@volkswagen-onehub/service-config-provider";

export interface IImprintProps {
    readonly localeService: LocaleServiceV1;
    readonly consentUrl: string;
}

export interface IImprintState {
    imprintText: string;
    imprintLines: string[];
    imprintLanguage: string;
}

export class ImprintPage extends React.Component<IImprintProps, IImprintState> {
    public readonly state: IImprintState = {
        imprintText: '',
        imprintLines: [],
        imprintLanguage: 'en'
    };

    public async componentDidMount() {
        const language = this.props.localeService !== undefined ? this.props.localeService.language : 'en';
        const imprintUrl = this.props.consentUrl + '/consent/v1/texts/consent/de/' + language + '/volkswagen-imprint/latest/markdown';
        const response = await fetch(imprintUrl);
        const responseText = await response.text();

        const lines = this.transformImprintText(responseText);
        this.setState(
            {
                imprintText: responseText,
                imprintLines: lines, 
                imprintLanguage: language
            });
    }

    public render() {
        return (
            <ThemeProvider theme={'main'}>
                <Container stretchContent={true} wrap={ContainerWrap.always}>
                    {this.state.imprintLines.map((item, key) =>
                        <div key={key} dangerouslySetInnerHTML={{__html: item}} />
                    )}
                </Container>
            </ThemeProvider>
        );
    }

    
    private transformImprintText(imprintText: string): string[] {
        const ret: string[] = [];
        const lines = imprintText.split('\n');
        
        for(let i=1; i<lines.length; i++) {
            ret.push(lines[i].trim());
        }
        return ret;
    }
};
