import {FeatureAppDefinition, FeatureAppEnvironment, FeatureServices} from "@feature-hub/core";
import {HistoryServiceV2} from "@feature-hub/history-service";
import {ReactFeatureApp} from "@feature-hub/react";
import {LocaleServiceV1} from "@volkswagen-onehub/locale-service";
import {ServiceConfigProviderV1} from "@volkswagen-onehub/service-config-provider";
import React from 'react';
import {Router} from "react-router";
import {Routes} from "./components/routes";

// TODO: add 'styled-components' v4 to externals when it is available on all stages
const dependencies = {
    featureServices: {
        's2:history': '2.0.0',
        'locale-service': '1.0.0',
        'service-config-provider': '1.0.0'
    },
    externals: {
        'react': '^16.8.2',
//        'styled-components': '^3.4.10',
        '@feature-hub/react': '^2.0.0'
    }
};

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp> = {
    dependencies,
    create: (env: FeatureAppEnvironment<FeatureServices, unknown>) => {
        const serviceConfigProvider: ServiceConfigProviderV1 = env.featureServices['service-config-provider'] as any;
        const historyService = env.featureServices['s2:history'] as HistoryServiceV2;
        const localeService: LocaleServiceV1 = env.featureServices['locale-service'] as LocaleServiceV1;

        let consentUrl = 'https://consent.vwgroup.io';
        try {
            const consentConfigUrl = serviceConfigProvider.configs['consent']!.urlOrigin;
            consentUrl = consentConfigUrl;
        } catch(e) {
            console.info('Consent URL not configured');
        }

        return {
            render() {
                return (
                    <React.Suspense fallback={<React.Fragment/>}>
                        <Router history={historyService.history}>
                            <Routes localeService={localeService} consentUrl={consentUrl} />
                        </Router>
                    </React.Suspense>
                )
            }
        }
    }
};
export default featureAppDefinition;
